const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://gy4d99e5ad.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://ty40d586tc.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://vyw52fua2l.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.evolution.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.evolution.forwoodsafety.com',
    WEBSOCKET: 'wss://n85utmo5h5.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;